<script lang="ts">
</script>
<svg 
   xmlns="http://www.w3.org/2000/svg"
   width="448"
   height="448"
   viewBox="0 0 448 448"
   version="1.1" class="{$$restProps.class || ''}">>
  <title
     id="title3340">chevron-down</title>
  <defs
     id="defs4" />
  <sodipodi:namedview
     id="base"
     pagecolor="#ffffff"
     bordercolor="#666666"
     borderopacity="1.0"
     showgrid="true"
     fit-margin-top="448"
     fit-margin-right="384"
     fit-margin-left="0"
     fit-margin-bottom="0"
     units="px">
    <inkscape:grid
       type="xygrid"
       id="grid3347"
       spacingx="16"
       spacingy="16"
       empspacing="2"
       originx="0"
       originy="-1.7498462e-005" />
  </sodipodi:namedview>
  <metadata
     id="metadata7">
    <rdf:RDF>
      <cc:Work>
        <dc:format>image/svg+xml</dc:format>
        <dc:type/>
        <dc:title>chevron-down</dc:title>
      </cc:Work>
    </rdf:RDF>
  </metadata>
  <g
     id="layer1"
     transform="translate(0,-604.36224)">
    <path
       style="fill-opacity:1;stroke-width:32;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none"
       d="M 384,784.79929 346.53984,748.36226 223.99999,867.55528 101.46016,748.36226 64,784.79929 l 37.42566,36.40349 0.0344,-0.0335 L 224,940.36226 346.53983,821.16924 l 0.0344,0.0335 37.42568,-36.4035 z"
       id="rect4331" />
  </g>
</svg>