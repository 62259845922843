<script lang="ts">
    import { Link } from "svelte-navigator";
    export let label: string;
    export let link: string;
</script>

<div class="link link-primary">
    <Link to={link}>{label}</Link>
</div>

