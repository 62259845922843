<script lang="ts">
    import AnswerResults from "./AnswerResults.svelte";
    import { Question, ReportData } from "../report-data/index.js";
    import { toTruthy } from "../dao/index.js";
    import { slide } from "svelte/transition";
    import ExpandIndicator from "./ExpandIndicator.svelte";
    import { createEventDispatcher } from "svelte";

    export let question: Question;
    export let submissions: NonNullable<ReportData["tests"][number]["submission"]>[];
    export let showPercentage: boolean;
    export let numberFormatter: Intl.NumberFormat;
    export let open: boolean;
    export let learners: number;

    const dispatch = createEventDispatcher<{open: boolean}>()
    
    let answerIds: string[];
    let questionSubmissions: typeof submissions;
    
    $: answerIds = toTruthy(question.answers).map(a => a.id);
    $: questionSubmissions = submissions.filter(s => answerIds.includes(s.answer!.id))
</script>

<div class="flex flex-col">
    <a class="cursor-pointer flex flex-row items-center gap-3" on:click={() => dispatch("open", !open)}>
        <ExpandIndicator {open} clockwise={false}/>
        <p>
          {question.text}
        </p>
    </a>
    {#if open}
        <div transition:slide class="pl-7 pt-6 pb-3">
            <AnswerResults {learners} {numberFormatter} {showPercentage} answers={toTruthy(question.answers)} submissions={questionSubmissions}/>
        </div>
    {/if}
</div>

