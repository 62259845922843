<script lang="ts">
  import {
    createRender,
    createTable,
    DataBodyRow,
  } from "svelte-headless-table";
  import { addSortBy, addColumnOrder } from "svelte-headless-table/plugins";
  import { Writable, writable } from "svelte/store";
  import { StudentPerformance } from "./StudentPerformance.js";
  import { ReportData, encodeEmail, ResultSet } from "../report-data";
  import { StudentPerformanceResolver } from "./StudentPerformanceResolver.js";
  import Table from "./Table.svelte";
  import LinkCell from "./LinkCell.svelte";
  import { ReportPath } from "./ReportPath.js";
  import { questionFilterParam } from "./questionsFilterParam.js";
  import { toStudentName } from "./toStudentName.js";
  import flatten from "lodash/flatten.js";
  import pick from "lodash/pick.js";
  import { toTruthy } from "../dao/index.js";
  import { toPercentageString } from "./toPercentageString.js";
  import { toFractionValue } from "./toFractionValue.js";
  import { missingValue } from "./missingValue.js";
  import { QuestionTableState } from "./QuestionTableState.js";
  import { createEventDispatcher } from "svelte";
  import { LearnerTableState } from "./LearnerTableState.js";
  import { nameKey } from "./nameKey.js";

  export let data: ReportData;
  export let rows: Writable<object[]>;
  export let numberFormatter: Intl.NumberFormat;
  export let state: LearnerTableState;

  const studentPerformanceParser = new StudentPerformanceResolver();
  const dataStore = writable<StudentPerformance[]>([]);
  const dispatch = createEventDispatcher<{ update: LearnerTableState }>();

  function toQuestionLink(email: string): string {
    const filterParams: Partial<QuestionTableState> = {
      dataFilter: {
        learners: [encodeEmail(email)],
      },
    };
    return `../${ReportPath.Questions}?${questionFilterParam}=${JSON.stringify(filterParams)}`;
  }

  function toCsvRow(row: StudentPerformance): Pick<
    StudentPerformance,
    "email" | "firstName" | "lastName"
  > & {
    quizzesServed: number;
    scheduledQuizzesServed: number;
    distinctQuestionsAsked: number;
    scheduledQuizzesAnswered: number;
    distinctQuestionsAnswered: number;
    distinctQuestionsAnsweredCorrectly: number;
  } {
    const result = toResultSet(row);
    return {
      ...pick(row, ["email", "firstName", "lastName"]),
      quizzesServed: result.toTests({}).length,
      scheduledQuizzesServed: result.toTests({ scheduledOnly: true }).length,
      distinctQuestionsAsked: result.toTests({ latest: true }).length,
      scheduledQuizzesAnswered: result.toSubmissions({ scheduledOnly: true })
        .length,
      distinctQuestionsAnswered: result.toSubmissions({ latest: true }).length,
      distinctQuestionsAnsweredCorrectly: result.toSubmissions({
        latest: true,
        correctOnly: true,
      }).length,
    };
  }

  $: $dataStore = studentPerformanceParser.parse(data);
  $: $rows = $dataStore.map((row) => toCsvRow(row));

  function toResultSet(row: StudentPerformance): ResultSet {
    const questions = toTruthy(
      flatten(data.reteyner.topics?.map((t) => t?.questions)),
    );
    return new ResultSet(row.tests, questions, [row]);
  }

  const table = createTable(dataStore, {
    sort: addSortBy(),
    colOrder: addColumnOrder(),
  });
  const columns = table.createColumns([
    table.column({
      header: "Name",
      id: nameKey,
      accessor: (row) => toStudentName(row),
    }),
    table.column({
      header: "Engagement",
      accessor: (row) => toFractionValue(toResultSet(row).engagement),
      cell: (data) => toPercentageString(data.value),
    }),
    table.column({
      header: "Mastery",
      accessor: (row) => toFractionValue(toResultSet(row).mastery),
      cell: (d) =>
        d.value === missingValue
          ? "-"
          : createRender(LinkCell, {
              label: toPercentageString(d.value),
              link: toQuestionLink(
                (d.row as DataBodyRow<StudentPerformance>).original.email,
              ),
            }),
    }),
  ]);
  const viewModel = table.createViewModel(columns);
  const { sortKeys } = viewModel.pluginStates.sort;

  $: $sortKeys = state.sortKeys;

  $: dispatch("update", {
    sortKeys: $sortKeys,
  });
</script>

<Table {viewModel} />
