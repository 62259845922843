import FileSaver from "file-saver";
import { unparse } from "papaparse";
import startCase from "lodash/startCase";
import camelCase from "lodash/camelCase";

export function downloadReport<T extends object>(
  rows: T[],
  title: string,
): void {
  const rowsWithHeadings = rows.map((row) =>
    Object.fromEntries(
      Object.entries(row).map(([key, value]) => [
        startCase(camelCase(key)),
        value,
      ]),
    ),
  );
  const resultsString = unparse(rowsWithHeadings);
  const resultsBlob = new Blob([resultsString], {
    type: "text/plain;charset=utf-8",
  });
  const dateString = new Date().toISOString().substring(0, 10);
  const downloadFilename = title.replace(" ", "_") + "_" + dateString + ".csv";
  FileSaver.saveAs(resultsBlob, downloadFilename);
}
