import groupBy from "lodash/groupBy.js";
import flatten from "lodash/flatten.js";
import { toTruthy } from "../dao/index.js";
import type { Question } from "./Question.js";
import type { Submission } from "./Submission.js";
import type { Test } from "./Test.js";
import { filterBatches } from "./filterBatches.js";
import { Learner } from "./Learner.js";

export class ResultSet {
  constructor(
    private allTests: Test[],
    private questions: Question[],
    private learners: Learner[],
  ) {}

  get tests(): Test[] {
    const questionIds = this.questions.map((q) => q.id);
    const emails = this.learners.map((l) => l.email);
    return this.allTests.filter(
      (t) =>
        questionIds.includes(t.questionId) &&
        t.contact &&
        emails.includes(t.contact.email),
    );
  }

  groupByQuestionAndUser(
    tests: Test[],
  ): Record<string, Record<string, Test[]>> {
    return Object.fromEntries(
      Object.entries(groupBy(tests, (t) => t.questionId)).map(
        ([questionId, tests]) => [
          questionId,
          groupBy(tests, (t) => t.contact!.email),
        ],
      ),
    );
  }

  filter<T extends { createdAt: string }>(
    request: { latest?: boolean; scheduledOnly?: boolean },
    transformer: (test: Test) => T | null | undefined,
  ): T[] {
    const tests = this.tests.filter(
      (t) => !request.scheduledOnly || !t.userRequested,
    );
    return flatten(
      Object.values(this.groupByQuestionAndUser(tests)).map((testsByUser) =>
        filterBatches(
          Object.values(testsByUser).map((items) =>
            toTruthy(items.map(transformer)),
          ),
          request,
        ),
      ),
    );
  }

  toTests(request: { latest?: boolean; scheduledOnly?: boolean }): Test[] {
    return this.filter(request, (t) => t);
  }

  toSubmissions(request: {
    latest?: boolean;
    scheduledOnly?: boolean;
    correctOnly?: boolean;
  }): Submission[] {
    const answers = toTruthy(flatten(this.questions.map((q) => q.answers)));
    return this.filter(request, (t) => t.submission).filter(
      (s) =>
        !request.correctOnly ||
        answers.find((a) => a.id === s.answer?.id)?.correct,
    );
  }

  get accuracy(): number {
    const params = {
      latest: true,
    };
    return (
      this.toSubmissions({ ...params, correctOnly: true }).length /
      this.toSubmissions(params).length
    );
  }

  get mastery(): number {
    const correctSubmissions = this.toSubmissions({
      latest: true,
      correctOnly: true,
    });
    const learnerQuestions = this.questions.length * this.learners.length;
    return correctSubmissions.length / learnerQuestions;
  }

  get engagement(): number {
    const params = { scheduledOnly: true };
    return this.toSubmissions(params).length / this.toTests(params).length;
  }
}
