import type { V6Client } from "@aws-amplify/api-graphql";
import type { ReteynSchema } from "../schema/index.js";
import { parseTruthyResponse } from "../dao/index.js";
import type { ReportData } from "./ReportData.js";

export class DataResolver {
  constructor(private client: V6Client<ReteynSchema>) {}

  async resolve(reportId: string): Promise<ReportData> {
    const input = {
      reportId,
    };
    const [report, reteyner, students, tests] = await Promise.all([
      parseTruthyResponse(this.client.queries.report(input)),
      parseTruthyResponse(this.client.queries.reteyner(input)),
      parseTruthyResponse(this.client.queries.students(input)),
      parseTruthyResponse(this.client.queries.tests(input)),
    ]);

    return {
      report,
      reteyner,
      students: students,
      tests: tests,
    };
  }
}
