import flatten from "lodash/flatten.js";
import orderBy from "lodash/orderBy.js";

export function filterBatches<T extends { createdAt: string }>(
  items: T[][],
  request: { latest?: boolean },
): T[] {
  return flatten(
    items.map((batch) =>
      orderBy(batch, "createdAt", "desc").filter(
        (_, index) => !request.latest || index === 0,
      ),
    ),
  );
}
