<script lang="ts">
    import { Answer } from "../report-data/index.js";

    export let answer: Answer;
    export let total: number;
    export let count: number;
    export let max: number;
    export let showPercentage: boolean;
</script>
<div class="w-full flex flex-col gap-1">
    <div class="flex justify-between w-full gap-1">
        <div>A{answer.index + 1}: {answer.text}</div>
        {#if showPercentage}
            <div>{total ? Math.round(100 * count / total) : 0}%</div>
        {/if}
    </div>
    <progress class="progress w-full {answer.correct ? "progress-success" : "progress-error"}" {max} value={count}></progress>
</div>
