<script lang="ts">
    import { Answer, Submission } from "../report-data/index.js";
    import groupBy from "lodash/groupBy";
    import sum from "lodash/sum";
    import { sortTruthy } from "../dao/index.js";
    import AnswerResult from "./AnswerResult.svelte";

    export let answers: Answer[];
    export let showPercentage: boolean;
    export let submissions: Submission[];
    export let numberFormatter: Intl.NumberFormat;
    export let learners: number;

    let answerSubmissions: Record<string, typeof submissions>;
    let counts: number[];
    let total: number;
    let max: number;

    let summaryMessage: string;

    $: answerSubmissions = Object.fromEntries(Object.entries(groupBy(submissions, s => s.answer?.id)).filter(([answerId]) => answers.map(a => a!.id).includes(answerId)));
    $: counts = Object.values(answerSubmissions).map(items => items.length);
    $: total = sum(counts);
    $: max = Math.max(...counts);
    $: summaryMessage = total ? total === learners ? `Answered by all learners` : `Answered by ${numberFormatter.format(total)} of ${numberFormatter.format(learners)} learner${learners > 1 ? 's' : ''}` : `No answers submitted`
</script>

<div class="flex flex-col gap-3">
    {#each sortTruthy(answers) as answer, index}
        {@const count = submissions.filter(s => s.answer?.id === answer.id).length}
        <AnswerResult {count} {showPercentage} max={total} {total} answer={{
            ...answer,
            index
        }}/>
    {/each}
    {#if showPercentage}
        <div class="flex flex-row justify-end">
            {summaryMessage}
        </div>
    {/if}
</div>