<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import { downloadReport } from "./downloadReport";
    import NavBar from "./NavBar.svelte";
    import { Readable, writable, Writable } from "svelte/store";
    
    export let title: string;
    export let rows: object[];
    export let scrollTop: Readable<number>;
  
    const container: Writable<HTMLDivElement> = writable();
    const dispatch = createEventDispatcher<{scroll:number}>()
  
    $: {
      if ($container) {
        $container.scrollTop = $scrollTop;
      }
    }

</script>
<div class="card h-dvh flex items-center">
  <NavBar {title}>
    <div class="tooltip tooltip-left" data-tip="Download data in CSV format">
      <button
        class="btn btn-neutral btn-sm"
        on:click={() => downloadReport(rows, title)}>Download</button
      >
    </div>
  </NavBar>
  <div class="overflow-y-auto max-w-screen-lg w-full" bind:this={$container} on:scroll={() => dispatch("scroll", $container.scrollTop)}>
    <slot />
  </div>
</div>