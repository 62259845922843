<script lang="ts">
    import flatten from "lodash/flatten.js";
    import { Link } from "svelte-navigator";
    import { toTruthy } from "../dao/toTruthy.js";
    import { Question, ReportData, ResultSet } from "../report-data/index.js";
    import { Hero } from "../reteyn-components/index.js";
    import { toTitle } from "./toTitle.js";
    import { toFractionValue } from "./toFractionValue.js";
    import { toPercentageString } from "./toPercentageString.js";
    import { ReportPath } from "./ReportPath.js";

    export let formatter: Intl.NumberFormat;
    export let data: ReportData;

    let resultSet: ResultSet;
    let questions: Question[];
    
    $: questions = toTruthy(flatten(data.reteyner?.topics?.map(t => t?.questions)))
    $: resultSet = new ResultSet(data.tests, questions, data.students);

</script>

<Hero>
    {@const learners = data.students.length}
    {@const submissions = resultSet.toSubmissions({})}
    <div class="flex flex-col gap-6">
        <h1 class="text-5xl font-bold">{toTitle(data)}</h1>
        <div class="stats">
            <div class="stat">
                <div class="stat-title">
                    Engagement
                </div>
                <div class="stat-value">
                    {toPercentageString(toFractionValue(resultSet.engagement))}
                </div>
                <div class="stat-desc">
                    With {formatter.format(learners)} learner{learners === 1 ? "" : "s"}
                </div>
                <div class="stat-actions">
                    <Link to="{ReportPath.Learners}">
                        <button class="btn btn-primary">View learners</button>
                    </Link>
                </div>
            </div>
            <div class="stat">
                <div class="stat-title">
                    Mastery
                </div>
                <div class="stat-value">
                    {toPercentageString(toFractionValue(resultSet.mastery))}
                </div>
                <div class="stat-desc">
                    With {formatter.format(submissions.length)} submission{submissions.length === 1 ? "" : "s"}
                </div>
                <div class="stat-actions">
                    <Link to="{ReportPath.Questions}">
                        <button class="btn btn-primary">View questions</button>
                    </Link>
                </div>
            </div>
        </div>
    </div>

</Hero>
