<script lang="ts">
    import {
        TableViewModel,
        Subscribe,
        Render,
        HeaderRow,
        BodyRow,
        TableAttributes,
        TableBodyAttributes,
    } from "svelte-headless-table";
    import { Readable } from "svelte/store";
    import OrderIcon from "./OrderIcon.svelte";
    export let viewModel: TableViewModel<any>;
    let headerRows: Readable<HeaderRow<any>[]>;
    let rows: Readable<BodyRow<any>[]>;
    let tableAttrs: Readable<TableAttributes<any>>;
    let tableBodyAttrs: Readable<TableBodyAttributes<any>>;
    $: ({ headerRows, rows, tableAttrs, tableBodyAttrs } = viewModel);
</script>

<table {...$tableAttrs} class="table table-pin-rows">
    <thead>
        {#each $headerRows as headerRow (headerRow.id)}
            <Subscribe rowAttrs={headerRow.attrs()} let:rowAttrs>
                <tr {...rowAttrs}>
                    {#each headerRow.cells as cell (cell.id)}
                        <Subscribe
                            attrs={cell.attrs()}
                            props={cell.props()}
                            let:props
                            let:attrs
                        >
                            <th
                                {...attrs}
                                on:click={props.sort.toggle}
                                class="cursor-pointer"
                            >
                                <Render of={cell.render()} />
                                <OrderIcon order={props.sort.order} />
                            </th>
                        </Subscribe>
                    {/each}
                </tr>
            </Subscribe>
        {/each}
    </thead>
    <tbody {...$tableBodyAttrs}>
        {#each $rows as row (row.id)}
            <Subscribe rowAttrs={row.attrs()} let:rowAttrs>
                <tr {...rowAttrs}>
                    {#each row.cells as cell (cell.id)}
                        <Subscribe attrs={cell.attrs()} let:attrs>
                            <td {...attrs}>
                                <Render of={cell.render()} />
                            </td>
                        </Subscribe>
                    {/each}
                </tr>
            </Subscribe>
        {/each}
    </tbody>

</table>
