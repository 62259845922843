import { decodeEmail } from "./decodeEmail.js";
import type { ReportData } from "./ReportData.js";
import type { ReportDataFilter } from "./ReportDataFilter.js";

export function filterReportData(
  data: ReportData,
  params: ReportDataFilter,
): ReportData {
  const emails = params.learners.map((v) => decodeEmail(v));
  const students = data.students.filter(
    (s) => !emails.length || emails.includes(s.email),
  );
  const studentEmails = students.map((s) => s.email);
  const tests = data.tests.filter(
    (t) => t.contact?.email && studentEmails.includes(t.contact!.email),
  );
  const filteredData = {
    ...data,
    tests,
    students,
  };
  return filteredData;
}
