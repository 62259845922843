<script lang="ts">
    import { Chevron } from "../reteyn-components/index.js";

    export let open: boolean;
    export let disabled: boolean = false;
    export let clockwise: boolean = true;
</script>

<div class="h-4 w-4">
    <Chevron
        class="h-4 w-4 duration-300 {open
            ? clockwise
                ? 'rotate-180'
                : '-rotate-180'
            : ''} {disabled ? 'fill-base-300' : 'fill-base-content'}"
    />
</div>
