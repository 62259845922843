<script lang="ts">
  import {LoadingScreen} from "../reteyn-components/index.js";
  import { ReportData, DataResolver } from "../report-data/index.js"

  export let reportId: string;
  export let resolver: DataResolver;

  let dataPromise: Promise<ReportData>;

  $: dataPromise = resolver.resolve(reportId);
</script>

{#await dataPromise}
  <LoadingScreen />
{:then reportData}
  <slot {reportData} />
{:catch err}
  Error: {err.toString()}
{/await}
