import type { StudentPerformance } from "./StudentPerformance.js";
import type { ReportData } from "../report-data/index.js";
import { toTruthy } from "../dao/index.js";

export class StudentPerformanceResolver {
  parse(request: ReportData): StudentPerformance[] {
    const { students, tests } = request;
    return toTruthy(students).map((s) => ({
      email: s.email,
      firstName: s.firstName || "",
      lastName: s.lastName || "",
      tests: tests.filter((t) => t.contact?.email === s.email),
    }));
  }
}
