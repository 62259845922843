<script lang="ts">
    import { QuestionAccuracyRow } from "./QuestionAccuracyRow.js";
    import QuestionCell from "./QuestionCell.svelte";
    import type { Submission } from "../report-data/index.js";
    import { type Readable, type Writable } from "svelte/store";
    import ExpandIndicator from "./ExpandIndicator.svelte";
    import { sortTruthy } from "../dao/index.js";
    import isEqual from "lodash/isEqual.js";
    import unique from "lodash/uniq.js"

    export let grouped: boolean;
    export let isExpanded: Writable<boolean>;
	export let canExpand: Readable<boolean>;
    export let row: QuestionAccuracyRow;
    export let submissions: Submission[];
    export let showPercentage: boolean;
    export let numberFormatter: Intl.NumberFormat;
    export let expandedQuestions: Writable<string[]>;
    export let learners: number;

    let questionId: string | undefined;
    let open: boolean;

    $: questionId = row.question?.id;
    $: open = questionId ? $expandedQuestions.includes(questionId) : false;

    function onOpen(opened: boolean) {
        const newState = unique(sortTruthy([...$expandedQuestions, questionId])).filter(id => (id !== questionId) || opened);
            if (!isEqual($expandedQuestions, newState)) {
                $expandedQuestions = newState;
            }
    }

</script>

{#if row.question}
    {@const questionText = grouped ? `Q${row.question.index + 1}: ${row.question.text}` : row.question.text}
    <div class={grouped ? "pl-3 md:pl-7" : ""}>
        <QuestionCell {learners} on:open={(event) => onOpen(event.detail)} {open} {numberFormatter} {showPercentage} {submissions} question={{
            ...row.question,
            text: questionText
        }}/>
    </div>
{:else}
    <div class="flex flex-col">
        <a class="flex flex-row items-center gap-3 {$canExpand ? "cursor-pointer" : ""}" on:click={() => ($isExpanded = !$isExpanded)}>
            {#if $canExpand}
                <ExpandIndicator open={$isExpanded} clockwise={false}/>
            {/if}
            <p>
                {row.text}
            </p>
        </a>
    </div>
{/if}