<script lang="ts">
    import { resolveHomeSite } from "../config/index.js";
    import { Link } from "svelte-navigator";
    import logo from "../reteyn-components/Reteyn_AllWhite.svg";
    
    export let title: string;
    
    const homeSite = resolveHomeSite();
</script>

<div
    class="card-title py-4 bg-primary text-primary-content flex flex-row w-full px-4 justify-between"
>
    <a target="_blank" href={homeSite} class="max-lg:hidden">
        <img class="max-w-16" src={logo} alt="Reteyn logo" />
    </a>
    <Link to="">
        <h2 class="w-full max-w-screen-lg lg:text-center">
            {title}
        </h2>
    </Link>

    <slot />
</div>
