import App from "./App.svelte";
import { Amplify } from "aws-amplify";
import { resolveConfig } from "../config/index.js";
import { generateClient } from "aws-amplify/api";
import { ReteynSchema } from "../schema/index.js";

const config = await resolveConfig();
Amplify.configure({
  ...config,
  Auth: {
    Cognito: {
      allowGuestAccess: true,
      identityPoolId: config.Auth?.Cognito.identityPoolId as string,
    },
  },
});
const client = generateClient<ReteynSchema>({
  authMode: "identityPool",
});

const app = new App({
  target: document.body,
  props: {
    client,
    config,
  },
});
export default app;
