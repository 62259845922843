<script lang="ts">
    import { Writable } from "svelte/store";
    import writableDerived from "svelte-writable-derived";

    enum StateLabel {
        Mastery = "Mastery",
        Accuracy = "Accuracy"
    }

    export let showAccuracy: Writable<boolean>;
    let state: Writable<StateLabel>;
    $: state = writableDerived(showAccuracy, (tf: boolean) => tf ? StateLabel.Accuracy : StateLabel.Mastery, (label: StateLabel) => label === StateLabel.Accuracy);

</script>
<div class="join">
    {#each Object.values(StateLabel) as label}
        <input
            class="join-item btn btn-sm btn-neutral rounded-btn"
            type="radio"
            name="displayMode"
            aria-label="{label}"
            value={label}
            bind:group={$state}
        />
    {/each}
</div>
