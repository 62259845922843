<script lang="ts">
  import { ReteynSchema } from "../schema/index.js";
  import { Config } from "../config/index.js";
  import { V6Client } from "@aws-amplify/api-graphql";
  import { Router, Route, useLocation } from "svelte-navigator";

  import debounce from "lodash/debounce.js";
  import Report from "./Report.svelte";
  import StudentPerformanceTable from "./StudentPerformanceTable.svelte";
  import QuestionAccuracyTable from "./QuestionAccuracyTable.svelte";
  import { derived, Writable, writable } from "svelte/store";
  import { ReportPath } from "./ReportPath.js";
  import { questionFilterParam } from "./questionsFilterParam.js";
  import { DataResolver } from "../report-data/index.js";
  import DataView from "./DataView.svelte";
  import { toTitle } from "./toTitle.js";
  import Home from "./Home.svelte";
    import { nameKey } from "./nameKey.js";

  export let client: V6Client<ReteynSchema>;
  export let config: Config;
  let resolver: DataResolver;

  $: resolver = new DataResolver(client);
  const locale = navigator.language;

  const numberFormatter = new Intl.NumberFormat(locale);
  const rows: Writable<object[]> = writable([]);

  function fromSearchParams<T>(
    searchParams: URLSearchParams,
  ): Partial<T> {
    const params = searchParams.get(questionFilterParam);
    return params ? JSON.parse(params) : {};
  }

  function updateStateParams<T>(state: T) {
    const url = new URL(location.href);
    url.searchParams.set(questionFilterParam, JSON.stringify(state));
    url.search = decodeURIComponent(url.search)
    history.replaceState(null, "", url);
  }

  const onScroll = debounce((scrollState: number) => {
    const url = new URL(location.href);
    url.hash = scrollState + "";
    history.replaceState(null, "", url);
  }, 100);
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap"
    rel="stylesheet"
  />
  <link
    href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto+Slab:wght@100..900&display=swap"
    rel="stylesheet"
  />
  <style src="../reteyn-components/style.css"></style>
  <style src="./fonts.css"></style>
</svelte:head>
<Router>
  <Route path="/:id/*" let:params>
    <Report {resolver} reportId={params.id} let:reportData>
      <Route path="/">
        <Home data={reportData} formatter={numberFormatter}></Home>
      </Route>
      <Route path="/*">
        {@const scrollTop = derived(useLocation(), location => parseInt(location.hash.replace("#", "")))}
        <DataView {scrollTop} title={toTitle(reportData)} rows={$rows} on:scroll={(event) => onScroll(event.detail)}>
          <Route path="/{ReportPath.Learners}">
            {@const state = {sortKeys: [{ id: nameKey, order: "asc" }], ...fromSearchParams(new URLSearchParams(location.search))}}
            <StudentPerformanceTable
              {state}
              data={reportData}
              {rows}
              {numberFormatter}
              on:update={event => updateStateParams(event.detail)}
            />
          </Route>
          <Route path="/{ReportPath.Questions}">
            {@const state = { expandedRows: [], dataFilter: {learners: []}, showTopics: false, sortKeys: [], expandedQuestions: [], tableFilter: "", ...fromSearchParams(new URLSearchParams(location.search))}}
            <QuestionAccuracyTable
              {numberFormatter}
              {state}
              {reportData}
              {rows}
              on:update={event => updateStateParams(event.detail)}
             />
          </Route>
        </DataView>
      </Route>
    </Report>
  </Route>
</Router>
