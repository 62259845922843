import sum from "lodash/sum.js";

export function toEWA(values: number[]): number {
  const alpha = 1 / values.length;
  const maxT = values.length - 1;
  const weight = (t: number) => Math.pow(1 - alpha, maxT - t);
  const weightSum = sum(values.map((_, t) => weight(t)));
  const normalisedWeight = (t: number) => weight(t) / weightSum;
  const contributions = values.map((value, t) => value * normalisedWeight(t));
  console.log(values.map((_, t) => normalisedWeight(t)));
  return sum(contributions);
}
